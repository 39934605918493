@import "@styles/constants.scss";
@import "@styles/utils.scss";

.phoneNumber {
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    text-align: right;
    outline: none;
    border: none;
    font-weight: 500;
    font-size: 14px;
    font-family: Roboto Mono;
    flex: 1 1;
  }
}

.phoneSection {
  margin-top: 12px;
}

.rolesSection {
  margin-bottom: 30px;
}

.red {
  color: $critical500;
}

.error {
  color: $critical500;
  padding: 0 12px;
  font-size: 12px;
  text-align: end;
}

.userModalLoader {
  background-color: black;
}

.userModalOverlay {
  position: absolute;
  inset: 0;
  z-index: 100000;
  background: rgba(255, 255, 255, 0.4);
  overflow: hidden;
}
