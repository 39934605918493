.errorMessage {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  left: 50%;
  transform: translateX(-50%);

  text-align: center;
  padding-top: 100px;
  color: #828f99;
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-self: center;
}

.towerIcon {
  scale: 2;
  align-self: center;
  width: fit-content;
}

.title {
  margin: 32px 0px;
  font-weight: 300;
  font-size: 32px;
}

.refreshBtn {
  width: fit-content;
  align-self: center;
}
