@import "@styles/constants";

.internalItem {
  &.disabled {
    pointer-events: none;
    color: $surface200;
    * {
      color: $surface200;
    }
  }

  &.selected {
    background: #e8ebed;

    &:hover {
      background: #e8ebed;
    }
  }
}

.caption {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
