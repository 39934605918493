$side-padding: 14%;
$label-bottom: 8px;

.chartWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  background: #fddbd5;
  user-select: none;
  overflow: hidden;
}

.chart {
  position: relative;
  height: 100%;
  width: 100%;
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
}

.sidePadding {
  position: absolute;
  width: $side-padding;
  height: 100%;

  &.grey {
    background: #ceeacd;
  }

  &.orange {
    background: #fff7c9;
  }

  &.red {
    background: #fddbd5;
  }
}

.leftValue {
  transform: translateX(-130%);
}

.rightValue {
  transform: translateX(130%);
}

.ideal {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: $label-bottom 0px;
  // border-left: 1px solid #9DAAB5;
  height: 100%;
}

.idealValue {
  position: absolute;
  transform: translateX(10%);
  color: #00a64f;
}

.orange {
  background: #ceeacd;

  .leftValue,
  .rightValue {
    color: #e49846;
  }
}

.red {
  background: #fff7c9;

  .leftValue,
  .rightValue {
    color: #e06a60;
  }
}

.orange,
.red {
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: $label-bottom 0px;
}

.detection {
  position: absolute;
  height: 11px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 45%;
  background: #9daab5;
  padding: 2px;

  .arrowIcon {
    color: white;
    display: flex;

    &.reverted {
      transform: scale(-1);
    }
  }
}
