@import "@styles/constants";

.ptsPage {
  display: flex;
  flex-direction: column;

  .header {
    font-weight: 600;
    font-size: 24px;
  }
}

.columnContent {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0.5rem 0.25rem;
}

.title {
  &::first-letter {
    text-transform: capitalize;
  }
}

.subtitle {
  font-size: 0.9em;
  font-weight: lighter;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-rows: auto;
  text-align: center;
  gap: 5px;

  span {
    background: rgba(255, 255, 255, 0.1);
    padding: 2px 4px;
    border-radius: 2px;
    grid-row: 1;

    &::first-letter {
      text-transform: capitalize;
    }
  }
}

.backBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  transition: background 0.2s;
  border-radius: 4px;
  background: #5d5b6d15;

  &:hover {
    background: #5d5b6d2c;
  }
}

.details {
  background: #5d5b6d15;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  grid-gap: 4px;
  margin-bottom: 18px;
  cursor: pointer;
  transition: background 0.2s;
  border-radius: 4px;

  &:hover {
    background: #5d5b6d2c;
  }

  .info {
    grid-row: 2;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto;
    grid-row-gap: 4px;
    grid-column-gap: 18px;
    padding: 8px;

    span:nth-child(odd) {
      text-transform: uppercase;
      text-align: start;
      color: #a9b1b8;

      font-family: Roboto Mono;
      font-style: normal;
      font-weight: 500;
      padding-right: 0;
    }

    span:nth-child(even) {
      color: #ffffff;
      padding-left: 0;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    span {
      padding: 4px 8px;
      border-radius: 2px;
    }
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.activeButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  user-select: none;
}

.submitButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 100px;
  height: 30px;
  margin-top: 30px;
  font-size: 14px;
  border: 1px solid white;
  border-radius: 4px;
  padding: 18px;
  transition: background 0.2s;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
}

.group {
  background: rgb(26, 30, 35);
  padding: 20px 30px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: rgba(26, 30, 35, 0.2);
  }
}
