.tooltipWrapper {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.tooltip {
  pointer-events: none;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  column-gap: 10px;
  line-height: 16px;
  border-radius: 2px;
  padding: 4px 6px;
  max-width: 250px;
  $tooltip-color: rgba(0, 0, 0, 0.85);
  background: $tooltip-color;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  &.noFilters {
    column-gap: 0px;
  }

  // Tooltip arrow
  &::after {
    content: "";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  span:nth-child(odd) {
    font-size: 11px;
    text-transform: uppercase;
    text-align: end;
    color: #a9b1b8;

    font-family: Roboto Mono;
    font-style: normal;
    font-weight: 500;
  }

  span:nth-child(even) {
    color: #ffffff;
    font-size: 12px;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.notificationsGroup {
  margin-bottom: 50px;

  table tbody tr td:first-letter {
    text-transform: capitalize;
  }
}

.severity {
  display: flex;
  align-items: center;
  justify-content: center;
}

.notificationRow {
  th {
    position: relative;
  }
}
