.logo {
  .symbol {
    fill: #43828a;
  }

  .letters {
    path {
      fill-rule: evenodd;
      fill: white;
    }
  }
}
