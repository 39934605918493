@import "@styles/constants.scss";
@import "@styles/utils.scss";

.popular {
  display: flex;
  flex-direction: column;
}

.caption {
  text-transform: capitalize;
  padding-bottom: rem(16px);
  @include WorkSansCaption;
}

.buttons {
  display: flex;
  align-items: center;
  gap: rem(8px);
}
