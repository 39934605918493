@import "@styles/constants";

.groupHeader {
  display: flex;
  color: #ffffff;
  margin-bottom: 20px;
  align-items: center;

  label {
    font-weight: 600;
    font-size: 24px;
    margin-right: auto;
  }
}

.selectedItems {
  span {
    font-weight: 500;
    font-size: 14px;
    .green {
      color: #39b5b2;
    }
  }
}

.deleteBtn {
  margin-left: 16px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #bd4e2b;
  background: rgba(189, 78, 43, 0.2);

  &:hover {
    background: rgba(189, 78, 43, 0.3);
  }
}

.toggleAactiveBtn {
  margin-left: 16px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #a9b1b8;
  background: rgba(#a9b1b8, 0.2);

  &:hover {
    background: rgba(#a9b1b8, 0.3);
  }

  &.active {
    color: #39b5b2;
    background: rgba(#39b5b2, 0.2);

    &:hover {
      background: rgba(#39b5b2, 0.3);
    }
  }
}

.addItem {
  display: inline-block;

  text-align: center;
  font-size: 14px;
  border: 1px solid white;
  border-radius: 4px;
  padding: 12px;
  white-space: nowrap;
  font-weight: normal;
  margin-left: 35px;
  transition: background 0.2s;

  i {
    margin-right: 0.5em;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }
}
