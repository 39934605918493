@import "@styles/constants.scss";
@import "@styles/utils.scss";

.usersTable {
  flex: 1;
}

.chipContainer {
  gap: rem(5px);
  display: flex;
  flex-wrap: wrap;
}

.userChip {
  background: rgba(127, 144, 158, 0.3);
  color: white;
  border: none;
}
