@import "constants";
@import "reset";
@import "fonts";

@import "~@frontend/assaia-ui/dist/styles/index.scss";
@import "~@frontend/assaia-ui/dist/index.esm.css";

body,
html {
  margin: 0;
  padding: 0;
  font-family: "Work Sans", sans-serif;
  font-weight: normal;
  font-size: 14px;
  background: #1c2127;
  color: white;
}

* {
  box-sizing: border-box;
}

a {
  cursor: pointer;
  text-decoration: none;
}

.assaia-ui-portal {
  z-index: 100;
}
