.timeaxis {
  user-select: none;
  width: 100%;
  height: 24px;
  background: #7f909e;
  display: flex;
  align-items: flex-end;

  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  position: relative;
  z-index: 100;
  overflow: hidden;
}

.tick {
  position: absolute;
  height: 5px;
  transform: translateX(-1px);
  border-left: 3px solid #9daab5;
}

.tickValue {
  position: absolute;
  color: #dee3e6;
  transform: translate(-50%, -140%);
  white-space: nowrap;
}

.axisContainer {
  z-index: 101;
  width: 100%;
  height: 0px;
  position: relative;
}

.axis {
  position: absolute;
  bottom: 0;
  border-left: 1px dashed rgba(127, 144, 158, 0.3);
  height: 80px;
  transform: translate(0px, 100%);
}
