.dropdown {
  display: inline-block;
  position: relative;

  a.toggle {
    display: inline-flex;
    background: #353e47;
    min-height: 30px;
    align-items: center;
    padding: 0px 8px;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.02em;
    width: 100%;
    color: #e2e2e2;

    span {
      flex: 1;
    }

    .fa-angle-down {
      margin-left: 10px;
      position: relative;
      top: 1px;
      font-size: 20px;
    }

    &:hover {
      opacity: 1;
      background: lighten(#353e47, 20%);
    }
  }

  .dropdown-menu {
    position: absolute;
    top: calc(100% + 4px);
    background: #353e47;
    margin: 0;
    min-width: calc(100% - 2px);
    white-space: nowrap;
    padding: 0px;
    list-style: none;
    border-radius: 3px;
    z-index: 150;
    overflow: auto;

    &.left-align {
      left: 0px;
    }

    &.right-align {
      right: 0px;
    }

    a {
      color: white;
      padding: 12px 16px;
      display: block;
      text-decoration: none;
      cursor: pointer;
      font-size: 16px;
      border-left: 5px solid transparent;
      transition: background 0.2s;

      &.highlighted {
        background: rgba(0, 0, 0, 0.2);
      }

      &.active {
        border-left-color: white;
      }
    }
  }
}
