.columnContent {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0.5rem 0.25rem;
}

.title {
  &::first-letter {
    text-transform: capitalize;
  }
}

.subtitle {
  font-size: 0.9em;
  font-weight: lighter;
  display: flex;
  gap: 5px;

  span {
    background: rgba(255, 255, 255, 0.1);
    padding: 2px 4px;
    border-radius: 2px;

    &::first-letter {
      text-transform: capitalize;
    }
  }
}
