.chart {
  height: 80px;
}

.intervalGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;

  input {
    width: 100%;
  }
}
