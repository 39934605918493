@import "@styles/constants";

.backdrop {
  z-index: 9999999;
}

.yesNoModal {
  z-index: 2000;
  width: 300px;
  background: white;
  min-height: unset;
}

.buttons {
  display: flex;

  .danger {
    background: #bd4e2b;

    &:hover {
      background: tomato !important;
    }
  }
}
