@import "@styles/constants";

.usersPage {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 0 $desktop-padding;
  scrollbar-gutter: stable;
}

.pageButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  bottom: 0;
  padding: 8px;
}
