@import "@styles/constants";

.header {
  padding: 0 $desktop-padding;
  min-height: 60px;
  max-height: 60px;
  justify-content: space-between;
  background: #2a3339;
  position: relative;
  display: flex;
  align-items: center;
}

.logo {
  height: 25px;
  max-width: 130px;
  margin-right: auto;
}

.nav {
  min-height: 52px;
  display: flex;
  align-items: stretch;
  background: #161619;
  padding: 0 $desktop-padding;

  a {
    border-bottom: 2px solid transparent;
    font-size: 16px;
    color: #a9b1b8;
    display: flex;
    align-items: center;
    margin-right: 25px;
    text-decoration: none;
    font-weight: 500;

    &:global(.active) {
      border-bottom-color: white;
      color: white;
    }
  }
}

.userDropdown {
  margin-left: 25px;
  background: none;
  white-space: nowrap;

  &:active {
    user-select: none;
  }

  :global(.toggle.toggle) {
    background: none !important;
    text-transform: none !important;

    font-size: 16px;
    font-family:
      Work Sans,
      sans-serif;
    color: #a9b1b8;

    & > span {
      display: flex;
      align-items: center;
      gap: 10px;

      :global(.far) {
        margin-left: 0;
      }
    }

    & > i {
      display: none;
    }

    img {
      height: 32px;
    }

    &:hover {
      color: #c5ced5;
    }
  }

  :global(.dropdown-menu) {
    background: #353e47;
    font-family: "Roboto Mono", monospace;

    .highlighted {
      background: none !important;
    }
  }
}

.userMenuItem.userMenuItem.userMenuItem {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 400;
  font-size: 14px;

  &.userMenuItemActive {
    font-weight: 500;
  }
}

.langIcon {
  max-height: 1em;
  max-width: 1em;
}
