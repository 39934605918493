@import "@styles/constants.scss";
@import "@styles/utils.scss";

.red {
  color: $critical500;
}

.filters {
  border-bottom: 1px solid rgba(127, 144, 158, 0.15);
}

.area {
  width: 100%;
  min-height: 122px;
  resize: none;
}

.charCount {
  align-self: flex-end;
  color: $surface500;
  padding-bottom: rem(12px);
  @include WorkSansCaption;
}
