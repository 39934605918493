@import "~@frontend/assaia-ui/dist/styles/constants.scss";

$bk-grey: #2a3135;
$bk-dark: #1b2127;
$grey-light: #efefef;
$grey-medium: #9b9b9b;
$grey-dark: #57595b;
$yellow: #ffb602;
$green: #009245;

$red: #ef1126;
$black-dark: rgb(28, 33, 37);
$text-black: #4a4a4a;
$orange: #ff7f6e;

$border-radius: 3px;
$desktop-padding: 28px;
