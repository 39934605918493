@import "@styles/constants";

.uiAlert {
  z-index: 2000;
  width: 400px;
  background: white;
  min-height: unset;
}

.text {
  font-size: 18px;
  margin: 0px;
  text-align: center;
  padding-bottom: 16px;
}
