@import "@styles/constants";

.group {
  margin-bottom: 50px;
}

.header {
  display: flex;
  color: #ffffff;
  margin-bottom: 20px;
  align-items: center;

  label {
    font-weight: 600;
    font-size: 24px;
    margin-right: auto;
  }
}
