@import "@styles/constants";

.search {
  position: relative;

  input {
    height: 30px;
    border-radius: 3px;
    appearance: none;
    border: none;
    width: 400px;
    padding: 0px 12px;
    outline: none;
    font-family: "Roboto Mono";
  }

  i {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    color: #8b8b8b;
    font-size: 14px;
  }
}
