.pageToolbar {
  flex: 1 1 auto;
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
  align-items: center;

  &:not(.withoutMargin) {
    margin-bottom: 16px;
  }
}

.pageToolbarItem {
  display: flex;
  align-items: center;
  gap: 8px;

  &.right {
    margin-left: auto;

    & + & {
      margin-left: 0;
    }
  }
}
