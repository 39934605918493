@import "@styles/constants.scss";
@import "@styles/utils.scss";

.caption {
  max-width: 30ch;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.error {
  color: $critical500;
}
