@import "@styles/constants";

.usersMenu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 55px;
  padding: 0 $desktop-padding;
  margin-top: $desktop-padding;
  gap: 20px;
  overflow-y: auto;
  scrollbar-gutter: stable;
}

.red {
  color: tomato !important;
}
