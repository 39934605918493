.previewContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  column-gap: 10px;
  background: #f5f7f8;
}

.axisItem {
  grid-row: 1;
}

.previewItem {
  height: 80px;
  grid-row: 2;
}
