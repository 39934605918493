@import "@styles/constants.scss";
@import "@styles/utils.scss";

.labelDropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: inherit;
}

.cell {
  min-height: rem(40px);
  min-width: rem(130px);
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: rem(5px);
}

.text {
  user-select: none;
  color: $surface600;
  border-bottom: 1px dashed $surface500;
  text-transform: capitalize;
}
