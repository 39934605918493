@import "@styles/constants";
@import "@styles/utils.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem(4px);
}

.green {
  color: $success300;
}

.red {
  color: $critical400;
}

.text {
  min-width: 3ch;
}
